<template>
  <v-container>
    <v-row>
      <v-col>
        <v-card>
          <v-card-title>Add New Project</v-card-title>
          <v-card-text>
            <v-col cols="6">
              <v-text-field v-model="project.name" label="Name of the Project (will be Url, please do NOT use special chars & spaces)" hint="please do NOT use special chars & spaces" persistent-hint placeholder="DO NOT use special chars & spaces"></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-textarea v-model="project.description" rows="5" label="Description"></v-textarea>
            </v-col>
            <v-col cols="6">
              <v-textarea v-model="project.message" rows="5" label="Message (with avatar)"></v-textarea>
              <v-text-field v-model="project.video" label="Vidyard UUID"></v-text-field>
            </v-col>
            <v-col>
              <v-text-field v-model="project.title" label="Title" hint="For example: .NET & React Fullstack Developers"></v-text-field>
              <v-text-field v-model="project.work_rate" label="Work Rate" hint="How much they will work"></v-text-field>
              <v-text-field v-model="project.start_date" label="Start Date" hint="When they should start"></v-text-field>
            </v-col>
            <v-col>
              <v-text-field v-model="project.requirement1" label="Requirement 1"></v-text-field>
              <v-text-field v-model="project.requirement2" label="Requirement 2"></v-text-field>
              <v-text-field v-model="project.requirement3" label="Requirement 3"></v-text-field>
            </v-col>
            <v-col>
              <v-text-field v-model="project.developers1" label="Our Developers 1"></v-text-field>
              <v-text-field v-model="project.developers2" label="Our Developers 2"></v-text-field>
              <v-text-field v-model="project.developers3" label="Our Developers 3"></v-text-field>
            </v-col>
            <v-col cols="2">
              <v-btn @click="postData" color="primary">Submit</v-btn>
            </v-col>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from "axios";

export default {
  name: "AddNewProjectPage",

  data() {
    return {
      project: {
        name: "",
        description: "",
        title: "",
        work_rate: "Full-time",
        start_date: "asap",
        requirement1: "",
        requirement2: "",
        requirement3: "",
        developers1: "",
        developers2: "",
        developers3: "",
        message: "",
        video: ""
      },
      loading: false,
    }
  },

  methods: {
    postData() {
      let self = this

      self.loading = true

      axios.get(`${this.$store.state.apiUrl}/sanctum/csrf-cookie`).then(response => {
        console.log(response)
        axios.post(`${this.$store.state.apiUrl}/api/projects`, self.project, this.$store.state.headers)
            .then(function () {
              self.$store.commit("displaySnackbar", {
                message: "New Project Added",
                color: "info"
              })
              self.loading = false
            })
            .catch(function (response) {
              alert(response.response.data.errors)
            })
      });
    },
  }
}
</script>

<style scoped>

</style>
